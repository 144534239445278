import './App.css';

function App() {
  return (
    <div className="App">
      <h1>hermanosvending.com is currently under construction. Please check back soon for the official site.</h1>
    </div>
  );
}

export default App;
